import { Fragment } from "react";
import Error404 from "../pages/Error-404/404";
import Footer from "../components/footer";
import Navbar from "../components/navbar";

const AppError = () => {
  return (
    <Fragment>
      <Navbar />
      <Error404 />
      <Footer />
    </Fragment>
  );
};

export default AppError;
