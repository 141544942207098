export const deluxeRooms = [
  {
    id: 1001,
    image: "deluxe1.jpg",
    title: "Classic Single Room",
    adults: 2,
    size: 410,
    subTitle: "All our Room have big windows to help you take a broad..",
    price: 1600,
  },
  {
    id: 1002,
    image: "deluxe2.jpg",
    title: "Couple Room",
    adults: 2,
    size: 410,
    subTitle: "All our Room have big windows to help you take a broad..",
    price: 1600,
  },
  {
    id: 1003,
    image: "deluxe3.jpg",
    title: "Deluxe Suite Room",
    adults: 2,
    size: 410,
    subTitle: "All our Room have big windows to help you take a broad..",
    price: 1600,
  },
  
 
];

export const executiveRooms = [
  {
    id: 2001,
    image: "executive1.jpg",
    title: "Classic Single Room",
    adults: 2,
    size: 410,
    subTitle: "All our Room have big windows to help you take a broad..",
    price: 2000,
  },
  {
    id: 2002,
    image: "executive2.jpg",
    title: "Couple Room",
    adults: 2,
    size: 410,
    subTitle: "All our Room have big windows to help you take a broad..",
    price: 2000,
  },
  {
    id: 2003,
    image: "executive3.jpg",
    title: "Deluxe Suite Room",
    adults: 2,
    size: 410,
    subTitle: "All our Room have big windows to help you take a broad..",
    price: 2000,
  },

];

export const luxuryRooms = [
  {
    id: 3001,
    image: "luxury1.jpg",
    title: "Classic Single Room",
    adults: 2,
    size: 410,
    subTitle: "All our Room have big windows to help you take a broad..",
    price: 1200,
  },
  {
    id: 3002,
    image: "luxury2.jpg",
    title: "Couple Room",
    adults: 2,
    size: 410,
    subTitle: "All our Room have big windows to help you take a broad..",
    price: 1200,
  },
  {
    id: 3003,
    image: "luxury3.jpg",
    title: "Deluxe Suite Room",
    adults: 2,
    size: 410,
    subTitle: "All our Room have big windows to help you take a broad..",
    price: 1200,
  },
 
];

export const standardRooms = [
  {
    id: 4001,
    image: "standard1.jpg",
    title: "Classic Single Room",
    adults: 2,
    size: 410,
    subTitle: "All our Room have big windows to help you take a broad..",
    price: 1200,
  },
  {
    id: 4002,
    image: "standard2.jpg",
    title: "Couple Room",
    adults: 2,
    size: 410,
    subTitle: "All our Room have big windows to help you take a broad..",
    price: 1200,
  },
  {
    id: 4003,
    image: "standard3.jpg",
    title: "Deluxe Suite Room",
    adults: 2,
    size: 410,
    subTitle: "All our Room have big windows to help you take a broad..",
    price: 1200,
  },
  
];

export const executiveRoomsImg = [
  "/images/executive1.jpg",
  "/images/deluxe12.jpg",
  '/public/assets/images/1x1grid.png',
  "/images/luxury1.jpg",
  "/images/Team-8.jpg",
  "/images/testa3.jpg",
  "/images/IMG_8439.jpg",
  "/images/IMG_8439.jpg",
  "/images/IMG_8439.jpg",
  "/images/carousel1.jpg",
  "/images/side_profile.jpg",
  "/images/Team-8.jpg",
  "/images/testa3.jpg",
  "/images/IMG_8439.jpg",
  "/images/IMG_8439.jpg",
  "/images/IMG_8439.jpg",
  "/images/carousel1.jpg",
  "/images/side_profile.jpg",
  "/images/Team-8.jpg",
  "/images/testa3.jpg",
  "/images/IMG_8439.jpg",
  "/images/IMG_8439.jpg",
];

export const deluxeRooma = [
  {
    id: 1001,
    image: "deluxe1.jpg",
     },
  {
    id: 1002,
    image: "deluxe2.jpg",
     },
  {
    id: 1003,
    image: "deluxe3.jpg",
      },
  {
    id: 1004,
    image: "deluxe4.jpg",
      },
  {
    id: 1005,
    image: "deluxe5.jpg",
      },
  {
    id: 1006,
    image: "deluxe6.jpg",
     },
  {
    id: 1007,
    image: "deluxe7.jpg",
     },
  {
    id: 1008,
    image: "deluxe8.jpg",
    },
  {
    id: 1009,
    image: "deluxe9.jpg",
      },
  {
    id: 1010,
    image: "deluxe10.jpg",
    },
    {
      id: 1011,
      image: "deluxe11.jpg",
        },
    {
      id: 1012,
      image: "deluxe12.jpg",
      }, {
        id: 1013,
        image: "deluxe13.jpg",
          },
      {
        id: 1014,
        image: "deluxe14.jpg",
        },
];

export const executiveRooma = [
  {
    id: 2001,
    image: "executive1.jpg",
    },
  {
    id: 2002,
    image: "executive2.jpg",
   },
  {
    id: 2003,
    image: "executive3.jpg",
   
  },
  {
    id: 2004,
    image: "executive4.jpg",
   },
  {
    id: 2005,
    image: "executive5.jpg",
   
  },
  {
    id: 2006,
    image: "executive6.jpg",
   },
  {
    id: 2007,
    image: "executive7.jpg",
   
  },
  {
    id: 2008,
    image: "executive8.jpg",
   },
   {
    id: 2009,
    image: "executive9.jpg",
   },
   {
    id: 2010,
    image: "executive10.jpg",
   },
   {
    id: 2011,
    image: "executive11.jpg",
   },
   {
    id: 2012,
    image: "executive12.jpg",
   },
   {
    id: 2013,
    image: "executive13.jpg",
   },
   {
    id: 2014,
    image: "executive14.jpg",
   },

];

export const luxuryRooma = [
  {
    id: 3001,
    image: "luxury1.jpg",
      },
  {
    id: 3002,
    image: "luxury2.jpg",
   
  },
  {
    id: 3003,
    image: "luxury3.jpg",
    
  },
  {
    id: 3004,
    image: "luxury4.jpg",
      },
  {
    id: 3005,
    image: "luxury5.jpg",
      },
  {
    id: 3006,
    image: "luxury6.jpg",
   
  },
  {
    id: 3007,
    image: "luxury7.jpg",
   },
  
];

export const standardRooma = [
  {
    id: 4001,
    image: "standard1.jpg",
    
  },
  {
    id: 4002,
    image: "standard2.jpg",
    
  },
  {
    id: 4003,
    image: "standard3.jpg",
    
  },
  {
    id: 4004,
    image: "standard4.jpg",
     },
  {
    id: 4005,
    image: "standard5.jpg",
     },
  {
    id: 4006,
    image: "standard6.jpg",
   
  },
  {
    id: 4007,
    image: "standard7.jpg",
    },
    
  ]

  
