import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { customers} from "../../components/constants/data";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./bookRooms.css";
import SearchIcon from "@mui/icons-material/Search";
import BookRoomsController from "./bookRooms-controller";

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { useState } from "react";


const BookRooms = () => {
  const useHomeController = BookRoomsController();
  const [showAlert, setShowAlert] = useState(false);
  const [selectedCheckIn, setSelectedCheckIn] = useState<Date | null>(null);
  const [selectedCheckOut, setSelectedCheckOut] = useState<Date | null>(null);
  const handleChangeCheckIn = (date: Date | null) => {
    setSelectedCheckIn(date);}
    const handleChangeCheckOut = (date: Date | null) => {
      setSelectedCheckOut(date);}
      

      const handleSearch = () => {
   
        setShowAlert(true);
      };
      const closeAlert = () => {
        setShowAlert(false);
      };
  return (
    <div className="flex flex-col justify-center items-center w-auto m-auto lg:flex-row bg-[#1d1b1a] pt-[6rem] p-[2rem] pb-[4rem] md:mt-0 lg:mt-5">
      <svg className="absolute blur-3xl right-[20%] opacity-80 z-1" width="60%" height="100%" viewBox="0 0 800 2000" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_17_60)"><g filter="url(#filter0_f_17_60)"><path d="M128.6 0H0V322.2L332.5 211.5L128.6 0Z" fill="#8A502E"></path><path d="M0 322.2V400H240H320L332.5 211.5L0 322.2Z" fill="#8A502E">
      </path><path d="M320 400H400V78.75L332.5 211.5L320 400Z" fill="#8A502E"></path><path d="M400 0H128.6L332.5 211.5L400 78.75V0Z" fill="#8A502E"></path></g></g><defs><filter id="filter0_f_17_60" x="-159.933" y="-159.933" width="719.867" height="719.867" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"> </feBlend><feGaussianBlur stdDeviation="79.9667" result="effect1_foregroundBlur_17_60"></feGaussianBlur> </filter></defs></svg>
    <div className="flex flex-col bg-white w-fit px-[2rem] z-10 lg:w-[36rem] p-[2rem] h-fit 2xl:py-[5rem] duration-100">
        <p className="text-[1.2rem] text-[#ab6034] ml-[1rem]">
          Booking Your Room
        </p>
        <h1 className="text-[2rem] m-[1rem]">Find & Booked Your Room</h1>
        <div className="flex flex-row m-[1rem] mb-[1.5rem] justify-between border-gray-200 border-b-[1px]">
          <label
            htmlFor="checkin"
            className="text-[1.2rem] m-[6px] h-fit text-left"
          >
            Check-in
          </label>
          <DatePicker
      selected={selectedCheckIn}
      onChange={handleChangeCheckIn}
      dateFormat="dd/MM/yyyy"
      placeholderText="&nabla;"
      showYearDropdown
      scrollableYearDropdown
      className=" text-right"
    />
        </div>
        <div className="flex flex-row m-[1rem] mb-[1.5rem] justify-between border-gray-200 border-b-[1px]">
          <label
            htmlFor="checkout"
            className="text-[1.2rem] m-[6px] h-fit text-left"
          >
            Check-out
          </label>
          <DatePicker
      selected={selectedCheckOut}
      onChange={handleChangeCheckOut}
      dateFormat="dd/MM/yyyy"
      placeholderText="&nabla;"
      showYearDropdown
      scrollableYearDropdown
      className=" text-right"
    />
        </div>
        <div className="flex flex-row m-[1rem] mb-[1.5rem] justify-between border-gray-200 border-b-[1px]">
          <label
            htmlFor="adults"
            className="text-[1.2rem] m-[6px] h-fit text-left"
          >
            Adults
          </label>
          <input
            id="adults"
            type="number"
            className="w-fit lg:p-[0.5rem]  text-center"
            placeholder="Adults"
            defaultValue={1}
            
          />
        </div>
        <div className="flex flex-row m-[1rem] mb-[1.5rem] justify-between border-gray-200 border-b-[1px]">
          <label
            htmlFor="children"
            className="text-[1.2rem] m-[6px] h-fit "
          >
            Children
          </label>
          <input
            id="children"
            type="number"
            className="w-fit lg:p-[0.5rem] text-center"
            placeholder="Children"
            defaultValue={0}
          />
        </div>
        {showAlert && (
        
          <div className="bg-orange-100 border-l-4 text-ellipsis overflow-hidden border-[#8A502E] text-orange-700 p-2" role="alert">
          <div className="mb-1 justify-end  flex items-start   ">
          <button className=" p-1 rounded items-start bg-[#8A502E] text-white  "
           onClick={closeAlert}>
              &#10007;
            </button></div>
            <p className="font-bold">Be Infromed</p>
            <p>It's technically closed right now.  Please contact us on this page. </p>
            <p> <a href="/rooms" className="font-bold text-orange-900 border-2 border-[#8A502E] "> Room</a></p>
           
          </div>)}
          
           {/* <input type="date" className="w-full border-gray-200 border-b-[1px] p-[0.5rem] m-[1rem] mb-[1.5rem]" placeholder="Check-out" />
          <input type="number" className="w-full border-gray-300 border-b-[1px] p-[0.5rem] m-[1rem] mb-[1.5rem] text-[1.3rem] font-black" placeholder="Adults" />
          <input type="number" className="w-full border-gray-300 border-b-[1px] p-[0.5rem] m-[1rem] mb-[1.5rem] text-[1.3rem] font-black" placeholder="Children" /> */}
        <button onClick={handleSearch} className="lg:m-[1rem] w-[100%] text-white font-medium bg-[#ab6034] text-[1.1rem] text-center hover:text-white py-[0.9rem] px-6 mt-6 mb-[2rem] border border-gray-400">
          Search Now{" "}

          {/* <SearchIcon style={{ fontSize: "1.5rem" }} className="ml-[1rem]" /> */}
        </button>
       
      </div>

      <div className="flex flex-col w-[100%] lg:w-[50rem] p-[0.5rem] lg:p-[2rem] lg:pl-[4rem] ">
        <p className="text-[1.3rem] font-medium text-[#ab6034] m-[1rem] mt-[4rem]">
          Our Testimonials
        </p>
        <h1 className="text-[2rem] font-medium text-white m-[1rem] mb-[2rem]">
          What Our Customer Say Us
        </h1>

        <div className="flex flex-col lg:flex-row">
          <Slider
            ref={useHomeController.sliderRef}
            {...useHomeController.settings2}
            className="w-full lg:w-[85%] h-fit pb-[2rem]"
          >
            {customers.map((cust, index) => (
              <div
                key={index}
                className="flex text-ellipsis overflow-hidden flex-col p-[2rem] lg:p-[2rem] pt-[4rem] bg-[#0c0b0b] w-fit lg:w-fit m-[1rem] h-fit min-h-[28rem]  lg:min-h-[22rem] md:min-h-[25rem] sm:min-h-[35rem]"
              >
                <p className="text-white ">{cust.content}</p>
                <div className="flex flex-col lg:flex-row mt-[2rem]">
                  <img
                    className="w-[3.4rem] h-[3.4rem] rounded-full mr-[1rem]"
                    src={`/assets/images/${cust.image}`}
                    alt="toilet"
                  />
                  <div className="flex flex-col">
                    <h1 className="text-[1.5rem] text-white">{cust.name}</h1>
                    <p className="text-[1.1rem] text-gray-300">
                      {cust.profession}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <div className="flex flex-row lg:flex-col w-full lg:ml-[2rem] p-[0.6rem] pt-[2rem]">
            {customers.map((cust) => (
              // <img
              //   key={cust.id}
              //   className={`w-[3.5rem] h-[3.5rem] rounded-full mb-[1rem] mr-[0.5rem] cursor-pointer ${
              //     cust.id === useHomeController.activeItem
              //       ? "border-[3px] border-[#ab6034]"
              //       : ""
              //   }`}
              //   src={`/assets/images/${cust.image}`}
              //   alt="customersName"
              //   onClick={() => useHomeController.handleStateChange(cust.id)}
              // />
              <h1  key={cust.id}
              className={`w-[3.5rem] h-[3.5rem] rounded-full mb-[1rem] mr-[0.5rem] s text-center justify-center items-center  text-[2rem] text-white  cursor-pointer ${
                cust.id === useHomeController.activeItem
                  ? "border-[3px] border-[#ab6034]"
                  : ""
              }`}
               onClick={() => useHomeController.handleStateChange(cust.id)}> {cust.name.charAt(0)}</h1>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookRooms;
