export const IMAGES = [
  "/assets/images/executive1.jpg",
  "/assets/images/executive2.jpg",
  '/assets/images/executive3.jpg',
  "/assets/images/executive4.jpg",
  "/assets/images/executive5.jpg",
  "/assets/images/executive6.jpg",
  "/assets/images/executive7.jpg",
  "/assets/images/executive9.jpg",
  "/assets/images/executive10.jpg",
  "/assets/images/executive11.jpg",
  "/assets/images/executive12.jpg",
  "/assets/images/executive13.jpg",
  "/assets/images/executive14.jpg",
  "/assets/images/luxury1.jpg",
  "/assets/images/luxury2.jpg",
  '/assets/images/luxury3.jpg',
  "/assets/images/luxury4.jpg",
  "/assets/images/luxury5.jpg",
  "/assets/images/luxury6.jpg",
  "/assets/images/luxury7.jpg",
  ];
  // export const executiveRoom = [
  //   "/assets/images/executive1.jpg",
  //   "/assets/images/executive2.jpg",
  //   '/assets/images/executive3.jpg',
  //   "/assets/images/executive4.jpg",
  //   "/assets/images/executive5.jpg",
  //   "/assets/images/executive6.jpg",
  //   "/assets/images/executive7.jpg",
  //   "/assets/images/executive9.jpg",
  //   "/assets/images/executive10.jpg",
  //   "/assets/images/executive11.jpg",
  //   "/assets/images/executive12.jpg",
  //   "/assets/images/executive13.jpg",
  //   "/assets/images/executive14.jpg",
   
     
  //   ];

  //   export const luxuryRoom = [
  //     "/assets/images/luxury1.jpg",
  //     "/assets/images/luxury2.jpg",
  //     '/assets/images/luxury3.jpg',
  //     "/assets/images/luxury4.jpg",
  //     "/assets/images/luxury5.jpg",
  //     "/assets/images/luxury6.jpg",
  //     "/assets/images/luxury7.jpg",
  //     "/assets/images/luxury9.jpg",
  //     "/assets/images/luxury10.jpg",
  //     "/assets/images/luxury11.jpg",
  //     "/assets/images/luxury12.jpg",
  //     "/assets/images/luxury13.jpg",
  //     "/assets/images/luxury14.jpg",
  //     ];
  //     export const deluxeRoom = [
  //       "/assets/images/deluxe1.jpg",
  //       "/assets/images/deluxe2.jpg",
  //       '/assets/images/deluxe3.jpg',
  //       "/assets/images/deluxe4.jpg",
  //       "/assets/images/deluxe5.jpg",
  //       "/assets/images/deluxe6.jpg",
  //       "/assets/images/deluxe7.jpg",
  //       "/assets/images/deluxe9.jpg",
  //       "/assets/images/deluxe10.jpg",
  //       "/assets/images/deluxe11.jpg",
  //       "/assets/images/deluxe12.jpg",
  //       "/assets/images/deluxe13.jpg",
  //       "/assets/images/deluxe14.jpg",
  //       ];
        export const deluxeRoom = [
          {
            id: 1001,
            image: "deluxe1.jpg",
            title: "Classic Single Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1600,
          },
          {
            id: 1002,
            image: "deluxe2.jpg",
            title: "Couple Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1600,
          },
          {
            id: 1003,
            image: "deluxe3.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1600,
          },
          {
            id: 1004,
            image: "deluxe4.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1600,
          },
          {
            id: 1005,
            image: "deluxe5.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1600,
          },
          {
            id: 1006,
            image: "deluxe6.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1600,
          },
          {
            id: 1007,
            image: "deluxe7.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1600,
          },
          {
            id: 1008,
            image: "deluxe8.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1600,
          },
          {
            id: 1009,
            image: "deluxe9.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1600,
          },
          {
            id: 1010,
            image: "deluxe10.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1600,
          },
        ];
        
        export const executiveRoom = [
          {
            id: 2001,
            image: "executive1.jpg",
            title: "Classic Single Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 2000,
          },
          {
            id: 2002,
            image: "executive2.jpg",
            title: "Couple Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 2000,
          },
          {
            id: 2003,
            image: "executive3.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 2000,
          },
        
        ];
        
        export const luxuryRoom = [
          {
            id: 3001,
            image: "luxury1.jpg",
            title: "Classic Single Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1200,
          },
          {
            id: 3002,
            image: "luxury2.jpg",
            title: "Couple Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1200,
          },
          {
            id: 3003,
            image: "luxury3.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1200,
          },
          {
            id: 3004,
            image: "luxury4.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1200,
          },
          {
            id: 3005,
            image: "luxury5.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1200,
          },
          {
            id: 3006,
            image: "luxury6.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1200,
          },
          {
            id: 3007,
            image: "luxury7.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1200,
          },
        ];
        
        export const standardRoom = [
          {
            id: 4001,
            image: "standard1.jpg",
            title: "Classic Single Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1200,
          },
          {
            id: 4002,
            image: "standard2.jpg",
            title: "Couple Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1200,
          },
          {
            id: 4003,
            image: "standard3.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1200,
          },
          {
            id: 4004,
            image: "standard4.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1200,
          },
          {
            id: 4005,
            image: "standard5.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1200,
          },
          {
            id: 4006,
            image: "standard6.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1200,
          },
          {
            id: 4007,
            image: "standard7.jpg",
            title: "Deluxe Suite Room",
            adults: 2,
            size: 410,
            subTitle: "All our Room have big windows to help you take a broad..",
            price: 1200,
          },]
