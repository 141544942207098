import React, { useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import Navbar from "../../components/navbar/navbar-view";
import "./about.css";
import { enjoyList, facilitie } from "../../components/constants/data";
import { rooms, AboutFacilities } from "../../components/constants/data";

const About: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <Navbar />
      <section
        className="relative bg-cover  bg-center bg-no-repeat"
        style={{ backgroundImage: `url('/assets/images/ranafall1.jpg')` }}
      >
        {" "}
        <div className="absolute inset-0 backdrop-brightness-50   sm:bg-transparent sm:from-black/95 sm:to-black/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"></div>
        <div className="relative mx-auto flex justify-center max-w-screen-xl text-center  items-center px-4 py-32 sm:px-6 lg:flex lg:h-[42rem] lg:items-center lg:px-8">
          <div className=" text-center justify-center">
            <h1 className="block font-extrabold text-white text-[4rem] sm:text-[5rem] lg:text-[6rem]">
              About
            </h1>
            <div className="mt-8 flex flex-wrap gap-4 text-center">
              <a
                href="/"
                className="block w-full rounded bg-rose-600 px-12 py-3 text-lg font-medium text-white shadow hover:bg-rose-700 focus:outline-none focus:ring active:bg-rose-500 sm:w-auto"
              >
                Home
              </a>
              <a
                href="#"
                className="block w-full rounded bg-white px-12 py-3 text-lg font-medium text-rose-600 shadow hover:text-rose-700 focus:outline-none focus:ring active:text-rose-500 sm:w-auto"
              >
                About Us
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-[#FFFFFF] pt-20">
        <div className="sm:flex p-2 lg:p-8 pt-[2rem]">
          <div className="flex-1">
            <p className="text-[1.2rem] lg:text-2xl pl-5 font-semibold mb-4">
              BEST AMENITIES
            </p>
            <h1 className="text-[2.4rem] lg:text-6xl p-[0.5rem] tracking-wider font-semibold mb-8 leading-relaxed lg:mt-[2.5rem]">
              Enjoy Your Vacation In <span className="text-[#9b804e]">Our</span>{" "}
              Hotel And Get Lots Of Fun, Happiness{" "}
              <span className="text-[#9b804e]">And</span> Great{" "}
              <span className="text-[#9b804e]">Pleasure</span>
            </h1>
          </div>
          <div className="flex-1 grid grid-cols-2 gap-8">
            {enjoyList.map((enj) => (
              <div key={enj.id} className="flex flex-col items-center">
                <div className="text-7xl mb-4 ">
                  {<enj.image style={{ fontSize: "4rem" }} />}
                </div>
                <div className="text-2xl font-semibold mb-2">{enj.title}</div>
                <div className="text-base">{enj.content}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="mx-auto max-w-lg text-center mt-16">
          <h1 className="text-[4rem] font-semibold sm:text-5xl underline pt-4">
            We Provide Amazing Service to Enjoy Your Days
          </h1>
        </div>

        <div className=" p-3 lg:p-8 mt-[2rem]">
          {/* <div className="w-1/5 h-2 bg-brown mx-auto mb-6"></div> */}
          {/* <div className="flex flex-wrap lg:grid grid-cols-3 ">
            {facilitie.map((fac) => (
              <div>
               <div key={fac} className="flex items-center m-[2rem] border-2 p-[1rem] bg-gray-300 chips ">
               <svg className=" object-cover object-center flex-shrink-0 rounded-full mr-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    color="#a45c32"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                > <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" className=""></path><polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
               <span className="text-lg">{fac}</span>
              </div>
                </div>
               
               
            ))}
            
          </div> */}

          <div className="mt-8 p-4 grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4 ">
            {AboutFacilities.map((fac, index) => (
              <a
                className="block justify-items-start  chips rounded-md  p-8 bg-[#F5F3F3] shadow-xl transition hover:border-pink-500/10 hover:shadow-pink-500/10"
                
              >
                <svg
                  className=" object-cover object-center flex-shrink-0 rounded-full mr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  color="#a45c32"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  {" "}
                  <path
                    d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
                    className=""
                  ></path>
                  <polyline points="22 4 12 14.01 9 11.01"></polyline>
                </svg>
                <div className="py-6 px-3 flex flex-col items-start">
                  <h2 className="mt-4  text-3xl py-4 justify-items-start subpixel-antialiased  font-monopy-4 font-bold text-gray-900">
                    {fac.title}
                  </h2>

                  <span className="text-xl justify-items-start py-6 text-gray-500">
                    {fac.content}
                  </span>
                  <span className="">
                    {
                      <fac.icon
                        style={{ fontSize: "5rem", color: "#a45c32" }}
                      />
                    }
                  </span>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
