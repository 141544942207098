import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useEffect, useState } from "react";
import Aos from "aos";
import "./footer.css";
import serviceCardStyle from "./service-card.module.css";

const footerImages = [
  {
    id: 1,
    image: "front1.jpg",
  },
  {
    id: 2,
    image: "restaurant01.jpg",
  },
  {
    id: 3,
    image: "executive1.jpg",
  },
  {
    id: 4,
    image: "garden1.jpg",
  },
];
 
const Footer = () => {
  useEffect(() => {
    Aos.init({ delay: 2000 });
  });
  const [inquiryEmail, setInquiryEmail] = useState<any>("");
  const [inquiryNumber, setInquiryNumber] = useState<any>();

  const handleInquiry = (e:any) => {
    e.preventDefault();
    if (
         !inquiryEmail ||
          !inquiryNumber
    ) {
      return alert("All Fields are mandatory!");
    }else {
  const subject = encodeURIComponent("Short Inquiry from " + inquiryNumber);
  const body = encodeURIComponent(`
    Email: ${inquiryEmail}
    Number: ${inquiryNumber}
   `);

  const mailtoUrl = `mailto:ravi@eurostarinn.com?subject=${subject}&body=${body}`;
   window.location.href = mailtoUrl;
  }
  };
  return (
    <div className="flex flex-col  z-[39]">
      <div className="flex flex-row overflow-auto no-scrollbar">
        {/* {footerImages.map((footerImage, index) => (
          <div
            className="relative w-full h-[15rem] lg:h-[25rem] lg:hover:scale-110 duration-2000"
            key={index}
          >
            <img
              src={`/assets/images/${footerImage.image}`}
              alt="footerimage"
              className="w-full h-full"
            />
            <div className="lg:animate-move-down p-[3rem] relative top-[-15.3rem] w-full h-full lg:absolute lg:top-0 inset-0 text-center opacity-0 hover:opacity-100 transition-opacity duration-300 flex flex-col justify-center text-white bg-[#ab6034] no-scrollbar">
              
              <div className="bg-white m-auto w-[5rem] h-[5px]"></div>
              <p className="text-[1.5rem] lg:text-[1.4rem] mb-[2rem]">
                Make A Call
              </p>
              <p className="text-[1.1rem] lg:text-[1.5rem] font-semibold mb-[2rem]">
                <a href="tel:+919981296029">+919981296029</a>
              </p>
            </div>
          </div>
        ))} */}
        {footerImages.map((footerImage, index) => (
          <div className={serviceCardStyle.card}>
            <div className={serviceCardStyle.face}>
              <div
                className={`${serviceCardStyle.face} ${serviceCardStyle.face1}`}
              >
                <div className={serviceCardStyle.content}>
                  <img src={`/assets/images/${footerImage.image}`} alt="" />
                </div>
              </div>
            </div>
            <div className={serviceCardStyle.face}>
              <div
                className={`${serviceCardStyle.face} ${serviceCardStyle.face2}`}
              >
                <div className={serviceCardStyle.content}>
                  <img
                    className="w-[4.3rem] h-[4.3rem] rounded-full mt-4 p-4 bg-gray-900  "
                    src={`/assets/images/favicon.ico`}
                    alt="favicon"
                  />
                  <div className="bg-white m-auto w-[5rem] h-[5px] mt-4"></div>
                  <a href="tel:+919981296029">
                    <p className="text-[1.5rem] text-white p-4 mt-4  lg:text-[1.4rem] ml-3 mb-[0.1rem]">
                      Make A Call
                    </p>
                  </a>
                  <p className="text-[1.1rem] text-white p-4 lg:text-[1.5rem] font-semibold mb-[2rem]">
                    <a href="tel:+919981296029">+91-9981296029</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex flex-col   justify-center items-center lg:flex-row pt-[4rem] pb-[2rem] bg-[#201d1b] p-[2rem] lg:justify-center lg:items-center">
        <div className="grid place-items-center md:grid-cols-1 lg:grid-cols-3 ">
        <div className="w-fit max-w-[28rem] ">
          <svg
            className="z-1 absolute blur-3xl right-50 mt-40 opacity-80"
            width="30%"
            height="30%"
            viewBox="100 100 400 700"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_17_60)">
              <g filter="url(#filter0_f_17_60)">
                <path
                  d="M128.6 0H0V322.2L332.5 211.5L128.6 0Z"
                  fill="#8A502E"
                ></path>
                <path
                  d="M0 322.2V400H240H320L332.5 211.5L0 322.2Z"
                  fill="#8A502E"
                ></path>
                <path
                  d="M320 400H400V78.75L332.5 211.5L320 400Z"
                  fill="#8A502E"
                ></path>
                <path
                  d="M400 0H128.6L332.5 211.5L400 78.75V0Z"
                  fill="#8A502E"
                ></path>
              </g>
            </g>
            <defs>
              <filter
                id="filter0_f_17_60"
                x="-159.933"
                y="-159.933"
                width="719.867"
                height="719.867"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood
                  flood-opacity="0"
                  result="BackgroundImageFix"
                ></feFlood>
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                >
                  {" "}
                </feBlend>
                <feGaussianBlur
                  stdDeviation="79.9667"
                  result="effect1_foregroundBlur_17_60"
                ></feGaussianBlur>{" "}
              </filter>
            </defs>
          </svg>

          <div className="flex flex-row">
            <a href="/" className="z-99 flex flex-row">
              <img
                className="w-[4.3rem] h-[4.3rem] rounded-full mr-2 mb-2 p-4 bg-gray-900  "
                src={`/assets/images/favicon.ico`}
                alt="favicon"
              />
              <h1 className="text-white text-[2.5rem] lg:mb-[0.5rem] font-semibold">
                EurostarInn
              </h1>
            </a>
          </div>
          <p className="text-gray-400 text-[1.08rem] mb-[0.5rem]">
            Elegance Personified, Service Exemplified. Where Elegance Meets
            Exceptional. Discover Tranquility, Stay in Style. Your Escape, Our
            Hospitality.
          </p>
          {/* <div className="flex flex-row w-fit my-[2rem] m-auto ">
            <div className="p-[0.5rem] rounded-full bg-[#0c0b0b] mr-[1.3rem]">
              <FacebookIcon style={{ fontSize: "1.5rem", color: "white" }} />
            </div>
            <div className="p-[0.5rem] rounded-full bg-[#0c0b0b] mr-[1.3rem]">
              <TwitterIcon style={{ fontSize: "1.5rem", color: "white" }} />
            </div>
            <div className="p-[0.5rem] rounded-full bg-[#0c0b0b] mr-[1.3rem]">
              <LinkedInIcon style={{ fontSize: "1.5rem", color: "white" }} />
            </div>
            <div className="p-[0.5rem] rounded-full bg-[#0c0b0b] mr-[1.3rem]">
              <YouTubeIcon style={{ fontSize: "1.5rem", color: "white" }} />
            </div>
          </div> */}

          <div className="flex flex-row  w-full my-[2rem] m-auto grid grid-cols-2 gap-3">
            <div className="p-[0.5rem] justify-items-center items-center rounded-sm bg-[#0c0b0b] mr-[1.3rem]">
              <p className="text-gray-200 text-ellipsis overflow-hidden text-center text-[1.1rem] ">
                &#9737; Transportation
              </p>
            </div>
            <div className="p-[0.5rem] justify-items-center items-center rounded-sm bg-[#0c0b0b] mr-[1.3rem]">
              <p className="text-gray-200 text-center text-[1.1rem] ">
                &#9737; YOGA Class
              </p>
            </div>
            <div className="p-[0.5rem] justify-items-center  items-center rounded-sm bg-[#0c0b0b] mr-[1.3rem]">
              <p className="text-gray-200 text-center text-[1.1rem] ">
                &#9737; SPA Treatment
              </p>
            </div>
            <div className="p-[0.5rem] justify-items-center items-center rounded-sm bg-[#0c0b0b] mr-[1.3rem]">
              <p className="text-gray-200 text-center text-[1.1rem] ">
                &#9737; Veg restaurant
              </p>
            </div>
          </div>
        </div>
        <div className="w-fit max-w-[25rem] m-auto lg:mb-[3rem] md:mb-[1.5rem]">
          <h1 className="text-[1.7rem] text-white">Quick Link</h1>
          <a href="/about" className="">
            <p className="text-gray-400 text-[1.1rem] mt-[0.6rem]">About Us</p>
          </a>
          <a href="#Services" className="">
            <p className="text-gray-400 text-[1.1rem] mt-[0.6rem]">Services</p>
          </a>
          <a href="/gallery" className="">
            <p className="text-gray-400 text-[1.1rem] mt-[0.6rem]">Gallery</p>
          </a>
          <a href="#News" className="">
            <p className="text-gray-400 text-[1.1rem] mt-[0.6rem]">
              Latest News
            </p>
          </a>
          <a href="/contact-us" className="">
            <p className="text-gray-400 text-[1.1rem] mt-[0.6rem]">
              Contact Us
            </p>
          </a>
           <a
            href="https://wa.me/919981296029"
            target="_blank"
            style={{ zIndex: 10000, opacity: 0.8 }}
          >
            <img
              src="assets/wappIcon.png"
              alt="WhatsApp"
              className="w-12 sm:w-12 md:w-12 lg:w-12 xl:w-12 mt-[0.5rem]"
            />
          </a>
        </div>
        {/* <div className="w-fit max-w-[20rem] m-auto mb-[2rem]">
          <h1 className="text-[1.7rem] text-white">Features</h1>
          <p className="text-gray-400 text-[1.1rem] mt-[0.6rem]">
            Transportation
          </p>
          <p className="text-gray-400 text-[1.1rem] mt-[0.6rem]">YOGA Class</p>
          <p className="text-gray-400 text-[1.1rem] mt-[0.6rem]">
            SPA Treatment
          </p>
          <p className="text-gray-400 text-[1.1rem] mt-[0.6rem]">
            veg restaurant
          </p>
          <p className="text-gray-400 text-[1.1rem] mt-[0.6rem]">Breakfast</p>
        </div> */}
        <div className=" z-99 w-fit max-w-[20rem] lg:m-auto mt-[2rem] mb-[2rem]">
          <h1 className="text-[1.7rem] text-white">Inquiry</h1>
          <input
            type="email"
            className="p-[1.8rem] w-full z-20 lg:w-[20rem] lg:h-[2rem] text-[1.2rem] text-gray-400 mt-[1rem]"
            placeholder="Email Address"
            value={inquiryEmail}
            onChange={(e) => setInquiryEmail(e.target.value)}
          />

          <input
            type="text"
            className="p-[1.8rem] w-full z-20 lg:w-[20rem] lg:h-[2rem] text-[1.2rem] text-gray-400 mt-[1rem]"
            placeholder="+91-0123456789"
            value={inquiryNumber}
           onChange={(e) => setInquiryNumber(e.target.value)}
          />

          <button
            onClick={handleInquiry}
            type="submit"
            className="text-white w-full text-[1.2rem] z-50 p-[0.8rem] px-[2rem] mt-[1rem] bg-[#ab6034]"
          >
            Send {"  "}  &#10148;
    
          </button>
        </div>
      </div> </div>
      <div className="flex flex-col justify-center items-center lg:flex-row lg:justify-between bg-[#1d1b1a] p-[2rem]">
        <div className="text-[1.2rem] text-white">
          © 2023 <span className="text-[#ab6034]">EurostarInn</span>. All Rights
          Reserved.
        </div>

        <div className="flex flex-row text-[1.15rem] text-gray-400">
          <p className="mr-[1.5rem]">Terms</p>
          <p className="mr-[1.5rem]">Privacy Policy</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
