import React from "react";
import "../App.css";
import {
  createBrowserRouter,
   RouterProvider,
} from "react-router-dom";
import AppLayout from "./app-layout";
import Home from "../pages/Home/index";
import About from "../pages/About/index";
import Rooms from "../pages/Rooms/index";
import RoomDetails from "../pages/RoomDetails/index";
import Contact from "../pages/Contact/index";
import Cart from "../pages/Cart/index";
import Gallery from "../pages/gallery";
import AppError from "./error-layout";

const AppRouter: React.FC = () => {
  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <AppLayout />,
      errorElement: <AppError />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/rooms",
          element: <Rooms />,
        },
        {
          path: "/rooms/:roomId",
          element: <RoomDetails />,
        },
        {
          path: "/contact-us",
          element: <Contact />,
        },
        {
          path: "/gallery",
          element: <Gallery />,
        },
        {
          path: "/cart",
          element: <Cart />,
        },
      ],
    },
  ]);
  return <RouterProvider router={appRouter} />;
};

export default AppRouter;
