import  {  useEffect, useState } from "react";
import Navbar from "../../components/navbar/navbar-view";
import { Link } from "react-router-dom";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import { useContactController } from "./contact-controller";

const Contact = () => {
  const contactController=useContactController();
  const [contactName, setcontactName] = useState<any>("");
  const [contactEmail, setcontactEmail] = useState<any>("");
  const [contactNumber, setcontactNumber] = useState<any>();
  const [contactSubject, setcontactSubject] = useState<any>("");
  const [contactMessage, setcontactMessage] = useState<any>("");
  

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleContact = (e:any) => {
    e.preventDefault();
    if (
      !contactSubject ||
      !contactEmail ||
      !contactName ||
      !contactNumber||
      !contactMessage
    ) {
      return alert("All Fields are mandatory!");
    }else {
  const subject = encodeURIComponent("contact  -" + contactSubject);
  const body = encodeURIComponent(`
   Dear Sir/Mam
    Thank you for contacting .. 

    Name: ${contactName}
    Email: ${contactEmail}
    Number: ${contactNumber}
    Subject: ${contactSubject}
    Message: ${contactMessage}

    Our Eurostarinn team will contact you soon
    
  `);

  const mailtoUrl = `mailto:ravi@eurostarinn.com?subject=${subject}&body=${body}`;
  window.location.href = mailtoUrl;
}
};
  return (
    <div>
      <Navbar />
   

<section
  className="relative bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url('/assets/images/contact01.jpg')` }}
>  <div
    className="absolute inset-0 backdrop-brightness-50   sm:bg-transparent sm:from-black/95 sm:to-black/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"
  ></div>
  <div
    className="relative mx-auto flex justify-center max-w-screen-xl text-center  items-center px-4 py-32 sm:px-6 lg:flex lg:h-[41rem] lg:items-center lg:px-8"
  >
    <div className=" text-center justify-center">
      <h1 className="block font-extrabold text-white text-[4rem] sm:text-[5rem] lg:text-[6rem]">
         
         Contact
        
      </h1>
        <div className="mt-8 flex flex-wrap gap-4 text-center">
        <a
          href="/"
          className="block w-full rounded bg-rose-600 px-12 py-3 text-lg font-medium text-white shadow hover:bg-rose-700 focus:outline-none focus:ring active:bg-rose-500 sm:w-auto"
        >
          Home
        </a>
        <a
          href="#"
          className="block w-full rounded bg-white px-12 py-3 text-lg font-medium text-rose-600 shadow hover:text-rose-700 focus:outline-none focus:ring active:text-rose-500 sm:w-auto"
        >
          Contact
        </a>
      </div>
    </div>
  </div>
</section>
    

      <div className="flex flex-col lg:flex-row py-[8rem] p-[1rem] lg:p-[3rem] justify-between">
        <div className="flex flex-col lg:w-1/2">
          <p className="text-[1.2rem] font-semibold text-[#b06940]">
            Contact Us
          </p>
          <h1 className="text-[2.8rem] font-semibold">
            Need Any Consultations to Booked your Seat
          </h1>
          <h1 className="text-[2.2rem] font-semibold mt-[2rem]">
            Khajuraho M.P., India
          </h1>
          <div className="w-[5rem] h-[4px] bg-[#ab6034] mb-[3rem]" />
          <div className="flex flex-row m-[1rem]">
            <AddLocationAltIcon
              style={{ fontSize: "4rem" }}
              className="text-[#ab6034]"
            />
            <div className="flex flex-col ml-[2rem]">
              <h2 className="text-[1.5rem] font-medium">Locations</h2>
              <p className="text-[1.5rem] text-gray-500">
              Main temple road, Khajuraho (Madhya Pradesh), India
              </p>
            </div>
          </div>
          <div className="flex flex-row m-[1rem]">
            <MarkEmailUnreadIcon
              style={{ fontSize: "4rem" }}
              className="text-[#ab6034]"
            />
            <div className="flex flex-col ml-[2rem]">
              <h2 className="text-[1.5rem] font-medium">Mail Support</h2>
              <p className="text-[1.5rem] text-gray-500">
                <a href="mailto:mymail@gmail.com">
                contactus@eurostarinn.com
                </a>
              </p>
            </div>
          </div>
          <div className="flex flex-row m-[1rem]">
            <AddIcCallIcon
              style={{ fontSize: "4rem" }}
              className="text-[#ab6034]"
            />
            <div className="flex flex-col ml-[2rem]">
              <h2 className="text-[1.5rem] font-medium">Make A Call</h2>
              <p className="text-[1.5rem] text-gray-500">
                <a href="tel:+1234567890">
                +919981296029
                </a>
              </p>
            </div>
          </div>
          <div className="flex flex-row m-[1rem]">
          <a
            href="https://wa.me/919981296029"
            target="_blank"
            style={{ zIndex: 10000, opacity: 1 }}
          >
            <img
              src="assets/wappIcon.png"
              alt="WhatsApp"
              className="w-14 sm:w-14 md:w-14 lg:w-16 xl:w-16"
            />
          </a>
            <div className="flex flex-col ml-[2rem]">
              <h2 className="text-[1.5rem] font-medium">Join Us</h2>
              <p className="text-[1.5rem] text-gray-500">
                <a href="https://wa.me/919981296029"  target="_blank">
                +91 9981296029
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="flex-col p-[1rem] py-[2rem] bg-[#f4f2f1] px-[2rem] lg:w-1/2">
          <h1 className="text-[2rem] font-medium">Send Us Message</h1>
          <p className="text-[1.2rem] text-gray-500">
            Your email address will not be published.
          </p>
          <form onSubmit={contactController.handleSubmit}>
          <div className="flex flex-row my-[2rem]">
            <input
              type="text"
              name="fullName"
              required
              
              className="p-[1rem] w-[45%] mr-[1.5rem]"
              placeholder="Full Name"
              value={contactName}
              onChange={(e) => setcontactName(e.target.value)}
            />
            <input
              type="number"
              className="p-[1rem] w-[45%]"
              placeholder="Phone"
              required
              
              name="phone"
              value={contactNumber}
              onChange={(e) => setcontactNumber(e.target.value)}
            />
          </div>
          <div className="flex flex-row my-[2rem]">
            <input
              type="text"
              className="p-[1rem] w-[45%] mr-[1.5rem]"
              placeholder="Email"
              required
              
              name="email"
              value={contactEmail}
              onChange={(e) => setcontactEmail(e.target.value)}
            />
            <input
              type="text"
              className="p-[1rem] w-[45%]"
              placeholder="Subject"
              required
              
              name="subject"
              value={contactSubject}
              onChange={(e) => setcontactSubject(e.target.value)}
            />
          </div>
          <textarea
            className="w-full h-[15rem] text-gray-600 my-[1rem] p-[1rem] resize-none"
            placeholder="Message"
            name="message"
            required
            // onChange={contactController.handleInput}
            // value={contactController.messageDetails.message}
            value={contactMessage}
              onChange={(e) => setcontactMessage(e.target.value)}
          />
          <button  onClick={handleContact} className="text-white text-[1.4rem] font-semibold bg-[#ab6034] px-[1.5rem] py-[0.5rem] my-[1rem]">
            Send Message {"  "}  &#10148;
           
          </button>
          </form>
        </div>
      </div>
      <div className="mb-[6rem]">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.7116859582525!2d79.9184163744449!3d24.839531546096435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3982e5f8e926d185%3A0x68227eed95fb6fa8!2sEuroStar%20Inn!5e0!3m2!1sen!2sin!4v1694623477282!5m2!1sen!2sin"
          className="w-full h-[100vh]"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
