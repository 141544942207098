import React from "react";
import useFooterController from "./rooms-controller";
import RoomDetails from "./room-details";

const RoomsDetail: React.FC<{}> = () => {
  const controller = useFooterController();
  return <RoomDetails {...controller} />

};

export default RoomsDetail;
