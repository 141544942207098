import PedalBikeIcon from '@mui/icons-material/PedalBike';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import BedIcon from '@mui/icons-material/Bed';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import SpaIcon from '@mui/icons-material/Spa';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import FestivalIcon from '@mui/icons-material/Festival';



export const enjoyList=[
  {
    id: 1,
    image: FastfoodIcon,
    title: 'Delicious Foods',
    content: `Indulge in Culinary Excellence: Savor Every Bite at Our Hotel's Delectable Dining Destinations.`,
  },
  {
    id: 2,
    image: SelfImprovementIcon,
    title: 'Yoga Center',
    content: `Dive into Tranquility: Refresh and Recharge at Our Inviting Yoga Facilities.`,
  },
  {
    id: 3,
    image: BedIcon,
    title: 'Double Beds',
    content: `Double the Comfort: Experience a Relaxing Stay in our Spacious Rooms with Cozy Double Beds.`,
  },
  {
    id: 4,
    image: SpaIcon,
    title: 'Massage Hub',
    content: `Unleash Fun and Adventure: Enjoy Boundless Joy in Our Exciting and Safe Massage Hub.`,
  },
];

export const facilitie=[
  
  "Wifi Facility available.",
  "Attached bath with running hot and cold water.",
  "Telephone and LCD TV with cable in each room.",
  "24-hours room service.",
  "Laundry and dry cleaning service.",
  "Doctor on call.",
  "Ample car parking facility.",
  "Airport transfers.",
  "Tour and Travels Information.",
  "Rent A Car.",
  "International & Domestic ticketing.",
  "Package tours.",
  "Foreign exchange facility.",
  "Reconfirmation of tickets.",
  "Hotel booking throughout India.",
  "Cable LCD T.V.",
  "Airport/Taxi assistance facility.",
  "Morning yoga classes and massage facility.",
  "Wake up call services, fax and Xerox.",
  "Conference room.",
  "Reception 24/7.",
  "Free parking.",
  "Choice of elegantly furnished air-conditioned and air-cooled rooms."
];

export const rooms = [
    {
      id: 'executive',
      image: "executive1.jpg",
      title: "Executive Rooms",
      adults: 2,
      size: 410,
      subTitle: "All our Room have big windows to help you take a broad..",
      price: 2000,
    },
    {
      id: 'deluxe',
      image: "deluxe12.jpg",
      title: "Deluxe Rooms",
      adults: 2,
      size: 410,
      subTitle: "All our Room have big windows to help you take a broad..",
      price: 1600,
    },
    {
      id: 'luxury',
      image: "luxury1.jpg",
      title: "Luxury Rooms",
      adults: 2,
      size: 410,
      subTitle: "All our Room have big windows to help you take a broad..",
      price: 1200,
    },
   
  ];
  
export const facilities=[
    // {
    //   icon: PedalBikeIcon,
    //   title: 'Fitness Center',
    //   content: 'At vero eos accusamus eustonis simos duc blanditiis praesente tatum',
    // },

    {
        icon: EmojiTransportationIcon,
        title: 'Transportation',
        content: 'Ride in Comfort and Style - Discover Convenience with Our Transportation Options.',
      },
      {
        icon: SpaIcon,
        title: 'Palmistry',
        content: 'Unlock Your Destiny - Explore Palmistry Services for a Glimpse into the Future.',
      },
      {
        icon: RestaurantIcon,
        title: 'Food & Restaurants',
        content: 'Culinary Delights Await - Indulge in a Symphony of Flavors at Our Restaurants.',
      },
      {
        icon: SelfImprovementIcon,
        title: 'Yoga Center',
        content: 'Zen Moments Begin Here - Rejuvenate Your Mind, Body, and Soul at Our Yoga Center.,'
      },
      {
        icon: SpaIcon,
        title: 'Massage Center',
        content: 'Indulge in a world of relaxation at our hotels massage center. Your journey to tranquility begins here..',
      },

      {
        icon: FestivalIcon,
        title: 'Tourism Guide',
        content: 'Journey into Discovery - Uncover Hidden Gems with Our Expert Tourism Guidance.',
      },
     
    
  ];

  export const AboutFacilities=[
    // {
    //   icon: PedalBikeIcon,
    //   title: 'Fitness Center',
    //   content: 'At vero eos accusamus eustonis simos duc blanditiis praesente tatum',
    // },

    {
        icon: EmojiTransportationIcon,
        title: 'Transportation',
        content: 'Ride in Comfort and Style - Discover Convenience with Our Transportation Options.',
      },
      {
        icon: SpaIcon,
        title: 'Palmistry',
        content: 'Unlock Your Destiny - Explore Palmistry Services for a Glimpse into the Future.',
      },
      {
        icon: RestaurantIcon,
        title: 'Food & Restaurants',
        content: 'Culinary Delights Await - Indulge in a Symphony of Flavors at Our Restaurants.',
      },
      {
        icon: SelfImprovementIcon,
        title: 'Yoga Center',
        content: 'Zen Moments Begin Here - Rejuvenate Your Mind, Body, and Soul at Our Yoga Center.,'
      },
      {
        icon: SpaIcon,
        title: 'Massage Center',
        content: 'Indulge in a world of relaxation at our hotels massage center. Your journey to tranquility begins here..',
      },

      {
        icon: FestivalIcon,
        title: 'Tourism Guide',
        content: 'Journey into Discovery - Uncover Hidden Gems with Our Expert Tourism Guidance.',
      },
      {
        icon: PedalBikeIcon,
        title: 'Event Facilities',
        content: 'Our hotel offers event spaces suitable for conferences, meetings, weddings, and other celebrations. ',
      },

      {
        icon: FestivalIcon,
        title: 'Tourism Guide',
        content: 'Journey into Discovery - Uncover Hidden Gems with Our Expert Tourism Guidance.',
      },
    
  ];

export const suites=[
    {
        title: 'Executive Room',
        subTitle: 'All our Rooms have big windows to help you take...',
        price: 2000,
        image: 'executive2.jpg'
    },
    {
        title: 'Deluxe Room',
        subTitle: 'All our Rooms have big windows to help you take...',
        price: 1600,
        image: 'deluxe2.jpg'
    },
    {
        title: 'Luxury Room',
        subTitle: 'All our Rooms have big windows to help you take...',
        price: 1200,
        image: 'luxury2.jpg'
    },
    {
        title: 'Standard Room',
        subTitle: 'All our Rooms have big windows to help you take...',
        price: 1200,
        image: 'standard2.jpg'
    }
]

export const customers=[
  {
    id: 0,
      content: 'At time of festival me with my friend visited khajuraho and Panna tiger reserve we enjoyed each and every movement of my trip we spend 2 night inn hotel eurostar inn khajuraho hotel is good well maintained hotel services are good restaurants around tasty food in discounted rate overall good experience thank you',
      name: 'Sachin M',
      profession: 'Guest',
      image: 'user.jpg'
  },
  {
    id: 1,
    content: 'I have visited this hotel with my friends we are very happy to stay there we have enjoyed very much . owner Ravi Mishra is also very good and friendly. Hotel facility is very good manager Mr Shiva is also very helpful person we enjoyed a lot wish you very e Goodluck Euro star inn',
    name: 'Akeel K',
    profession: 'Guest',
    image: 'user.jpg'
},
{
  id: 2,
  content: 'This Hotel is a perfect place to stay for budgeted visitors in Khajuraho heaving nice location very large and well furnished room, nice restaurant and food was too good . its just 10 Min to khajuraho temples. nice lobby and free WiFi for all in house guests.',
  name: 'Amitsupriya',
  profession: 'Guest',
  image: 'user.jpg'
},
{
  id: 3,
  content: 'we have booked ac deluxe room for 2 days and we found it to be one of best budget hotels in the same place. on next day we visited some other hotels also but we found this hotel was best and good. the staff was co-operative and the house cleaning used to be done every day. I Recommend this hotel for all. owner of the hotel is mr. arun mishra was a very nice person. every thing was working properly in the room. IF ONE VISIT THIS PLACE SHOULD HAVE A STAY OVER HERE.',
  name: 'Yogindery',
  profession: 'Guest',
  image: 'user.jpg'
},
]


export const news=[
  {
    image: 'khajurahoTemple.jpg',
    title: 'Khajuraho Temple',
    subTitle: ' Khajuraho is a town in the Indian state of Madhya Pradesh, known for its group of stunning medieval Hindu and Jain temples. These temples are renowned for their intricate and artistic erotic carvings, as well as their impressive architectural design. Heres some information about Khajuraho Temple:',
    author: 'Rivi Mishra',
    date: 'July 31, 2022',
  },
  {
    image: 'fall1.jpg',
    title: 'raneh falls',
    subTitle: 'Both Khajuraho and Raneh Falls offer unique experiences—one steeped in historical and architectural wonders, and the other in the natural beauty of waterfalls and canyons. Combining a visit to Khajurahos temples with a trip to Raneh Falls provides a diverse and enriching travel experience.',
    author: 'Rivi Mishramin',
    date: 'Dec 02, 2023',
  },
  {
    image: 'tiger.jpg',
    title: 'Panna Tiger Reserve',
    subTitle: 'Panna Tiger Reserve is renowned for its rich wildlife, including Bengal tigers, leopards, wild dogs, and various species of deer.,  stands as a testament to successful conservation efforts, providing a unique opportunity for nature enthusiasts and wildlife lovers to witness the beauty of the natural world in a protected environment.',
    author: 'Rivi Mishra',
    date: 'Dec 01, 2023',
  },

]

