import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/navbar-view";
import HeroSection from "../HeroSection";
import BriefSummary from "../BriefSummary";
import QualityShow from "../QualityShow";
import BookRooms from "../BookRoom";
import News from "../News";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";

const Home = () => {
  const [showWhatsAppWidget, setShowWhatsAppWidget] = useState(true);

  const closeWhatsAppWidget = () => {
    setShowWhatsAppWidget(false);
  };
  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <div>
      <Navbar />
      <HeroSection />
      <BriefSummary />
      <QualityShow />
      <BookRooms />
      <News />
      {showWhatsAppWidget && (
        <div className="fixed bottom-8 right-2 z-50 p-3">
          <a
            href="https://wa.me/919981296029"
            target="_blank"
            style={{ zIndex: 10000, opacity: 0.8 }}
          >
            <img
              src="assets/wappIcon1.png"
              alt="WhatsApp"
              className="w-24 sm:w-24 md:w-32 lg:w-38 xl:w-38"
            />
          </a>
          <button
            className="bg-black/30 text-red-500 px-1.5 iteam-center rounded-full absolute top-2 right-6  sm:top-2 sm:right-6  lg:top-4 lg:right-8 -mt-2 -mr-2"
            onClick={closeWhatsAppWidget}
          >
            &#10006;
          </button>
        </div>
      )}
    </div>
  );
};

export default Home;
