import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import error404Styles from "./404.module.css";

import { useCallback } from "react";
import toClass from "../../components/helpers/toClass";
import UIBtn from "../../widgets/ui-btn";

const Error404 = () => {

  const navigate = useNavigate();

  const Routes = useCallback(() => {
    return (
      <ul className="mt-8 flex flex-wrap gap-4 text-center">
        <li  className="block w-full rounded bg-rose-600 px-12 py-3 text-lg font-medium text-white shadow hover:bg-rose-700 focus:outline-none focus:ring active:bg-rose-500 sm:w-auto"
      >
          <Link to="/">Home</Link>
        </li>
        
        <li className="block w-full rounded bg-white px-12 py-3 text-lg font-medium text-rose-600 shadow hover:text-rose-700 focus:outline-none focus:ring active:text-rose-500 sm:w-auto"
      >Error 404</li>
      </ul>
    );
  }, []);

  const TopComponent = useCallback(() => {
    return (
      <div className={error404Styles.headerComponent}>
        <div className={error404Styles.headerRootComponent}></div>
        <div className="container-md items-center  justify-center">
          <Routes />
          <h1 className="block font-extrabold text-white text-[3rem] sm:text-[3rem] lg:text-[4rem]">
            Not Found</h1>
        </div>
      </div>
    );
  }, [Routes]);

  const IntroComponent = useCallback(() => {
    return (
      <div className={error404Styles.introRootComponent}>
        <h1 className={error404Styles.text404}>404</h1>
        <div className="divider">
          <div className={error404Styles.innerDivider}></div>
        </div>
        <p className={error404Styles.failureText}>
          Oops! It could be either you or us - there is no page here. <br /> It
          might have been moved or deleted
        </p>

        <UIBtn
          btnText="Go To Home"
          onClick={() => navigate("/")}
          className={{
            btn: toClass(["backGroundTheamColor",error404Styles.homeButton]),
          }}
        />
      </div>
    );
  }, [navigate]);

  return (
    <div>
      <TopComponent />
      <IntroComponent />
    </div>
  );
};

export default Error404;
